import React from "react";
import "./Aliados.scss";
import logo1 from "../images/alianzas/altoperu.JPG";
import logo2 from "../images/alianzas/arbio.jpeg";
import logo3 from "../images/alianzas/churumazu.jpg";
import logo4 from "../images/alianzas/ecoladrillos.jpg";
import logo5 from "../images/alianzas/giselvic.jpg";
import logo6 from "../images/alianzas/riaco.jpg";
import logo7 from "../images/alianzas/taller56.jpg";
import logo8 from "../images/alianzas/palmeral.jpg";
import logo9 from "../images/alianzas/andinobear.jpeg";
import logo10 from "../images/alianzas/amazonia_quelate.png";
import logo11 from '../images/alianzas/OÑEKER-LOGO.jpeg';

const logos = [
  logo1,
  logo3,
  logo2,
  logo5,
  logo7,
  logo4,
  logo6,
  logo9,
  logo8,
  logo10,
  logo11
];

export default function Aliados() {
  return (
    <section className="aliados-wrapper" id="aliados">
      <h2>Aliados</h2>
      <div>
        {logos.map((logo) => {
          return <img src={logo} alt={"logo"} />;
        })}
      </div>
    </section>
  );
}
