import React from "react";
import Project from "./Project";
import "./Proyectos.scss";
import puyu from "../images/helechoarboreo.jpg";
import puyu2 from "../images/puyu.jpeg";
import potsom from "../images/POTSOM_POSHOLL.jpg";
import sembrando from "../images/SEMBRANDO_mapa.JPG";
import bermejo from "../images/Bermejo.jpeg";

const projects = [
	{
		name: "POTSOM POSHOLL",
		desc: "Es un área de conservación privada (ACP) reconocida por el SERNANP mediante Resolución Ministerial Nº 060-2021-MINAM, desde marzo del 2021. Es un bosque de 35 hectáreas de las cuales 20 conforman el ACP Y 15 hectáreas corresponden a área de protección, Potsom Posho´ll se encuentra ubicado en el distrito de Palcazú, a 500 msnm, en la zona de amortiguamiento de la Reserva Comunal Yanesha. Dentro del bosque se encuentran grandes ejemplares de tornillo, chontaquiro, pino chuncho, copal, entre otras especies; además, alberga gran diversidad de especies de fauna como el armadillo gigante, el oso hormiguero gigante, el jaguar entre otros.",
		image: "https://www.youtube.com/embed/lXfg91L3IY4",
		extraImages: [potsom],
	},
	{
		name: "PUYU",
		desc: "Es un espacio de 1 ha, donde se está desarrollando un proyecto de vida sostenible. Se encuentra en la zona de amortiguamiento del Parque Nacional Yanachaga Chemillén, en el distrito de Oxapampa a 2200 msnm. Colinda con un bosque de 12 ha que conservamos junto con un grupo de amigos.",
		image: puyu,
		extraImages: [puyu2],
	},
	{
		name: "SEMBRANDO UN ÁRBOL EN EL BOSQUE DE NUBES",
		desc: "Es una iniciativa de restauración que busca involucrar a las personas con el bosque, proponiendo la plantación de árboles nativos en el bosque de PUYU, con la intención de enriquecerlo y beneficiar a la fauna silvestre. Este proyecto culminó con éxito en junio del 2021. Hemos logrado enriquecer un espacio de bosque secundario con 353 plantones de 9 especies. Pacaes, nogales, ulcumanos, diablos fuertes, cedros, sangres de grado, bobinsanas, palmas reales y robles son los nuevos habitantes del bosque de Puyu y atraerán a una gran diversidad de fauna y de polinizadores, logrando el equilibrio del ecosistema, y beneficiándonos todos de ello.",
		image: "https://www.youtube.com/embed/dA7sFEp70dA",
		extraImages: [sembrando],
	},
	{
		name: "CÁMARAS TRAMPA",
		desc: `Se instalaron cámaras trampa en Potsom Posho'll con la intención de identificar y monitorear la fauna silvestre, se encontraron distintos mamíferos y aves.`,
		image: "https://www.youtube.com/embed/anXAY9wKl-Q",
		extraImages: [],
	},
	{
		name: "PROYECTO BERMEJO",
		desc: `Es una iniciativa para desarrollar proyectos de investigación, restauración ecológica, desarrollo social, educación y deporte en el ámbito de los ecosistemas marino y desierto costero. El balneario Punta Bermejo se ubica a 224 kilómetros al norte de Lima, provincia de Huarmey, en la región Ancash. Cuenta con una gran belleza paisajística, diversidad de especies de fauna marino-costera y una excelente ola para el surf.  Actualmente estamos implementando un vivero forestal y un espacio de trabajo para elaborar futuros proyectos en la zona.`,
		image: bermejo,
		extraImages: [],
	},
];

const doubleVidProjects = [
	{
		name: "SENSIBILIZACIÓN",
		desc: `Desarrollar programas de educación ambiental que fomenten el cuidado de la naturaleza, resalten la importancia de las culturas ancestrales y nos enseñen a vivir en armonía con el planeta.`,
		vid1: "https://www.youtube.com/embed/3KxyPADbWFg",
		vid2: "https://www.youtube.com/embed/6pdB-Te78UI",
	},
];
export default function Proyectos() {
	return (
		<section id="proyectos">
			<h2>Proyectos</h2>
			{projects.map((prj) => (
				<Project key={prj.name} info={prj} />
			))}
			{doubleVidProjects.map((prj) => {
				return (
					<div className="double-project-wrapper">
						<div>
							<iframe
								width="560"
								title={prj.name}
								height="315"
								src={prj.vid1}
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							/>
							<iframe
								width="560"
								title={prj.name}
								height="315"
								src={prj.vid2}
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							/>
						</div>
						<div className="text-container">
							<h3>{prj.name}</h3>
							<p>{prj.desc}</p>
						</div>
					</div>
				);
			})}
		</section>
	);
}
