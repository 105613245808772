import React from 'react';
import './Unete.scss';

export default function Unete() {
  return (
    <section id="unete">
      <h2>ÚNETE…</h2>
      <div className="text-container">
        <p>
          Si quieres conocer más de nosotros <b>contáctanos</b>{' '}
        </p>
        <a href="mailto:ceare.peru@gmail.com">ceare.peru@gmail.com</a>
        <a href="tel:+51 975 285 231">+51 975 285 231</a>
        <a href="tel:+51 942702798">+51 942 702 798</a>
      </div>
    </section>
  );
}
