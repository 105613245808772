import React from "react";

export default function Person({ person }) {
	return (
		<div className="person-wrapper">
			<img src={person.image} alt={person.name} />
			<h4>{person.name}</h4>
			<h5>{person.role}</h5>
			<p style={person.name.includes("Omri") ? { textAlign: "center" } : {}}>
				{person.desc}
			</p>
		</div>
	);
}
