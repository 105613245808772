import React from "react";

export default function Project({ info }) {
	return (
		<section className="project-wrapper">
			<div>
				{info.name !== "PUYU" && info.name !== "PROYECTO BERMEJO" ? (
					<iframe
						width="560"
						title={info.name}
						height="315"
						src={info.image}
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					/>
				) : (
					<img width="560" height="315" src={info.image} alt={info.name} />
				)}
			</div>
			<div className="text-container">
				<h3>{info.name}</h3>
				<p>{info.desc}</p>
			</div>
			<div className="extra-images-container">
				{info.extraImages.map((img) => {
					return (
						<img
							style={{ maxWidth: 500 }}
							width="100px"
							alt="extra images"
							src={img}
						/>
					);
				})}
			</div>
		</section>
	);
}
