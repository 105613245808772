import React from 'react';
import './Navbar.scss';
import logo from './logo.png';
import youtube from './images/youtube.svg';
import facebook from './images/facebook.svg';
import instagram from './images/instagram.svg';

export default function Navbar() {
  const scrollTo = (e) => {
    e.preventDefault();
    const href = e.target.href.match(/#\w+/g)[0];
    const offsetTop = document.querySelector(href).offsetTop;

    window.scroll({
      top: offsetTop - 120,
      behavior: 'smooth',
    });
  };

  return (
    <div className="navbar-wrapper">
      <div className="navbar-content">
        <a href="#inicio">
          <div className="logo-container">
            <img className="navbar-logo" src={logo} alt="Ceare peru" />
            <h4>
              Colectivo Ecológico Amazonía Regenerativa{' '}
              {window.innerWidth > 600 ? '- CEARE' : ''}{' '}
            </h4>
          </div>
        </a>
        <div className="navbar-spacer" />
        <div className="navbar-social">
          <a
            href="https://www.youtube.com/channel/UC9EooDMS9EbDxI-hrUfeHhg"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="youtube" />
          </a>
          <a
            href="https://www.facebook.com/CEARE.PERU/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/ceare.peru/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
        </div>
        <div className="links-wrapper">
          <a onClick={scrollTo} href="#quienes">
            Quienes Somos
          </a>
          <a onClick={scrollTo} href="#equipo">
            Equipo
          </a>
          <a onClick={scrollTo} href="#proyectos">
            Proyectos
          </a>
          <a onClick={scrollTo} href="#aliados">
            Alianzas
          </a>
          <a onClick={scrollTo} href="#unete">
            Únete
          </a>
        </div>
      </div>
    </div>
  );
}
