import React from "react";
import "./Equipo.scss";
import Person from "./Person";
import carlos from "../images/equipo/Carlos_Llerena.jpg";
import carolina from "../images/equipo/carolina_llerena.jpg";
import diego from "../images/equipo/diego_alonso.jpg";
import luis from "../images/equipo/Luis_llerena.jpeg";
import rodrigo from "../images/equipo/rodrigo_carbajal.jpg";
import andrea from "../images/equipo/Andrea_Vera_Arabe.jpg";
import maria from "../images/equipo/Maria_luisa_vasquez_coda.jpeg";
import valvina from "../images/equipo/Valvina_Miguel.jpeg";
import diego_villaran from "../images/equipo/Diego_Villarán.jpeg";
import fortunato from "../images/equipo/Fortunato.jpeg";
import omri from "../images/equipo/omri.jpeg";

const team = [
	{
		name: "Carlos Llerena Pinto (†)",
		role: "Fundador",
		desc: "Ingeniero Forestal con Maestría en manejo forestal de la universidad de Alberta, Canadá. Fue un profesional con gran trayectoria que tuvo presencia en cada departamento del Perú y en 25 países de los cinco continentes. Trabajó por más de 30 años como docente de pre y post grado en la Universidad Nacional Agraria La Molina en el departamento de Manejo Forestal. Fue consultor, promotor de pequeña empresa, director, coordinador y evaluador de proyectos multidisciplinarios, supervisor logístico, editor, relacionista público y miembro/dirigente de asociaciones profesionales, científicas, gremiales y culturales. Tuvo especial interés en la amazonía andina y sus cuencas, con actividades profesionales como investigador y promotor de trabajos de investigación hidrológica y extensión en meso y micro-cuencas.",
		image: carlos,
	},
	{
		name: "Carolina Llerena Bermúdez",
		role: "Presidenta / Asociada fundadora",
		desc: "Ingeniera forestal, amante de la vida en todas sus expresiones y defensora de la naturaleza y de la cultura viva. Comprometida con el cuidado de los bosques co-administra el área de conservación privada Potsom Posho'll. Actual presidenta de la Red de iniciativas de áreas de conservación de Oxapampa – RIACO.",
		image: carolina,
	},
	{
		name: "Luis Llerena Bermúdez",
		role: "Vicepresidente / Asociado fundador",
		desc: "Ingeniero Forestal con experiencia en manejo de bosques tropicales con énfasis en la protección del medio ambiente, restauración ecológica y conservación. Consultor en programas de restauración en áreas degradadas por minería en la amazonía y para la gestión del riesgo de desastres en cuencas andino costeras. Participación en proyectos de manejo forestal con comunidades nativas y pueblos indígenas en situación de aislamiento y contacto inicial.",
		image: luis,
	},
	{
		name: "Rodrigo Carbajal Mas",
		role: "Coordinador / Asociado fundador ",
		desc: "Bachiller en ingeniería ambiental de la Universidad Nacional Agraria la Molina, con experiencia en instalación de biodigestores en zonas rurales. Ha realizado trabajos de comunicación audiovisual con comunidades nativas y centros poblados de la costa peruana. Es un activista en temas de conservación y restauración de bosques. Actualmente administra el área de conservación privada Potsom Posho´ll.",
		image: rodrigo,
	},
	{
		name: "Fortunato Vargas Rivera",
		// role: "Asociada",
		desc: "Fortunato es originario de la nación Yanesha. Su íntima relación con el bosque lo ha llevado a obtener un gran conocimiento de la diversidad de plantas del valle del Palcazu y su utilidad. Fortunato es el guardián del bosque de Potsom Posho'll, conoce todos sus rincones, sabe que animales se benefician de su conservación, y es el guía cuando llegan visitantes.",
		image: fortunato,
	},
	{
		name: "Valbina Olinda Miguel Toribio",
		// role: "Asociada",
		desc: "Originaria y representante de la nación Yanesha, Valbina Miguel es coordinadora nacional de la federación de comunidades Yanesha - FECONAYA. Ha sido reconocida como la primera mujer yanesha con registro nacional de intérprete y traductora de su idioma originario. Es artesana y fue una de las primeras organizadoras de las ferias de artesanías en su comunidad, Loma Linda, y en su distrito, Palcazú. En el año 2019 fue reconocida por el Congreso de la República del Perú por promover el arte y la cultura. Hoy preside la asociación Oñeker, dedicada a difundir el arte de su cultura. Su experiencia le ha dado muchas oportunidades como viajar a Francia para mostrar su arte, cultura y difundir la importancia del cuidado del bosque amazónico.",
		image: valvina,
	},

	{
		name: "Andrea Vera Arabe",
		// role: "Asociada",
		desc: "Ingeniera Forestal de la Universidad Nacional Agraria La Molina (Perú) y cuenta con una Maestría en Silvicultura Tropical (Tropical Forestry) de la Universidad Técnica de Dresden, Alemania. Es especialista en la planificación del uso del suelo, desarrollo de paisajes sostenibles, y en implementar acciones climáticas para alcanzar los objetivos de desarrollo sostenible.  Experta en proyectos forestales de carbono y de pago por servicios ambientales, con experiencia en los mercados internacionales de carbono y en proyectos bajo diferentes estándares como el VCS, CCBS y Gold Standard. Ha participado en la implementación de proyectos de REDD+ y AFOLU (Agricultura, Silvicultura y Otros Usos del Suelo), evaluación de metodologías de carbono, coordinación de proyectos de carbono y el desarrollo de mecanismos de Monitoreo, Reporte y Verificación (MRV).",
		image: andrea,
	},
	{
		name: "María Luisa Vásquez Coda",
		// role: "Asociada",
		desc: "Ingeniera Forestal de la Universidad Nacional Agraria La Molina, con maestría en Maestría en Silvicultura Tropical (Tropical Forestry) de la Universidad Técnica de Dresden, Alemania. Ha trabajado en toda la amazonía peruana con comunidades nativas, ribereñas y rurales. Cuenta con experiencia en proyectos REDD+, tenencia forestal, cambio de uso de suelo y motores de deforestación y medios de vida rurales.",
		image: maria,
	},

	{
		name: "Diego Alonso Carbajal Mas",
		// role: "Director de arte / Asociado fundador",
		desc: "Artista y diseñador dedicado al dibujo, escultura y elaboración de accesorios. Desde temprana edad estudió de manera autodidacta en diversos talleres de artesanos y artistas, desarrollando su afición por la creación y la transformación de la materia, siempre en contacto con la naturaleza, buscando en ella la fuente de inspiración para plasmarla en su obra, compenetrándose con el bosque transmitiendo la riqueza de la biodiversidad y la importancia de la protección de los ecosistemas y los pueblos originarios.",
		image: diego,
	},
	{
		name: "Omri Nuri",
		// role: "Asociada",
		desc: "Músico y programdor. Amante de la naturaleza.",
		image: omri,
	},
	{
		name: "Diego Villarán",
		// role: "Asociada",
		desc: `Surfista profesional y amante de la naturaleza. Asesor de impacto social, fundador del "Proyecto Alto Perú" y director del área de innovación social de esta institución, además es co-fundador del proyecto "Todos por el Morro", iniciativa que busca poner en valor este lugar histórico.`,
		image: diego_villaran,
	},
];

export default function Equipo() {
	return (
		<section id="equipo">
			<h2>Equipo</h2>
			<div className="people-container">
				{team.map((person) => (
					<Person key={person.name} person={person} />
				))}
			</div>
		</section>
	);
}
