import React from 'react';
import Carousel from './Carousel';
import './Start.scss';
import image1 from '../images/start/1.jpg'
import image2 from '../images/start/2.jpg'
import image3 from '../images/start/3.jpg'
import image4 from '../images/start/4.jpg'

const images = [
    {
        original: image1
    },
    {
        original: image2
    },
    {
        original: image3
    },
    {
        original: image4
    }
]

export default function Start(){
    
    return(
        <section id="inicio"  className='start-wrapper' >
            <Carousel images={images} />                
        </section>
    )
}