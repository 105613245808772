import React from 'react';
import ImageGallery from 'react-image-gallery';
import './Carousel.scss';

export default function Carousel({ images = [] }) {
  return (
    <ImageGallery
      items={images}
      showFullscreenButton={false}
      autoPlay={true}
      slideInterval={5000}
      showPlayButton={false}
      showThumbnails={false}
    />
  );
}
