import React from 'react';
import './QuienesSomos.scss';

export default function QuienesSomos() {
  return (
    <>
      <section id="quienes">
        <div>
          <h2>CEARE</h2>
          <p>
            Es una organización comprometida con la investigación, restauración
            ecológica, conservación de bosques y trabajo con comunidades nativas
            en la selva central del Perú.
          </p>
        </div>
      </section>
      <section className="mision ms-section">
        <div className="spacer" />
        <div className="text-container">
          <h2>MISIÓN</h2>
          <p>
            Involucrarnos en la regeneración amazónica promoviendo la
            conservación de los bosques y la cultura tradicional, así como la
            restauración ecológica y la investigación, mediante el trabajo
            conjunto con los actores locales, instituciones y centros
            educativos.
          </p>
        </div>
      </section>
      <section className="vision ms-section">
        <div className="spacer" />
        <div className="text-container">
          <h2>VISIÓN</h2>
          <p>
            Consolidarnos como una organización referente en proyectos
            sostenibles en la amazonía peruana.
          </p>
        </div>
      </section>
    </>
  );
}
