import './App.scss';
import Aliados from './components/Aliados';
import Equipo from './components/Equipo';
import Proyectos from './components/Proyectos';
import QuienesSomos from './components/QuienesSomos';
import Start from './components/Start';
import Unete from './components/Unete';
import Navbar from './Navbar';
import smoothscroll from 'smoothscroll-polyfill';
 
// kick off the polyfill!
smoothscroll.polyfill();


function App() {
  return (
    <div className="App">
      <Navbar/>
      <div className='main'>
        <Start/>
        <QuienesSomos/>
        <Equipo/>
        <Proyectos/>
        <Aliados/>
        <Unete/>
      </div>
    </div>
  );
}

export default App;
